
.BBHeaderMainBar {
    text-align: right;
    align-items: center; /* Center content vertically */
    justify-content: flex-end; /* Align content to the right */
  }
  
.BBHeaderMainBar img {
    padding-top: 0px;
    height: auto;
    width: 135px;
    padding-top: .5%;
    padding-right: .5%;
    padding-bottom: 0px;
  }

.BBbottom-right-image {
    position: fixed;
    bottom: 0px; /* Adjust this value to position the image vertically */
    right: 0px; /* Adjust this value to position the image horizontally */
    z-index: -1; 
    height: 350px;
    width: auto;
    opacity: 0.35;
  }
