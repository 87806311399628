@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.MyAccountSubheading {
    font-size:xx-large;
    color: white;
    font-family: 'Caveat';
}

  .noWagers {
    color: white;
    font-family: 'Caveat';
    cursor: pointer;
    text-align: center;
  }
  
  .button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  .button-container button {
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 200px;
    width: 120px;
    background: linear-gradient(180deg, 
    #ffffff 9%, #cd561b 50%);
  }
  
.NotificationButton{
    position: relative;
    padding: 5px;
    font-size: 15px;
    line-height: 10px; 
    cursor: pointer;
    color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 50px;
    width: 30px;
    background: linear-gradient(180deg, 
    #ffffff 9%, #cd561b 50%);

}
.NotificationBadge {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%); /* Center the badge */
    background-color: #ffffff; /* Change the background color as needed */
    color: rgb(9, 8, 8); /* Change the text color as needed */
    border-radius: 50%;
    font-size: 0.5rem;
}
.NotificationButton:hover::after {
    content: 'People want to wager';
    display: inline-block;
    white-space: nowrap; 
    position: absolute;
    font-size: 0.5rem;
    background-color: #cd561b;
    color: #fff;
    padding: 5px;
    border-radius: 15px;
    margin-top: -30px;
    z-index: 1;
  }

.notification{
    font-size: 24px;
    margin-bottom: 20px;
    color: white;
    font-family: 'Permanent Marker';
} 

.notificationdetails {
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: white;
    text-decoration: none;
    font-family: 'Permanent Marker';
    padding-left: 40px;
}

.notificationdetails a,.notificationdetails:visited{
    color: white;
    text-decoration: none;
}


.individualwagerlink:hover {
    text-decoration: underline;
  }
  body {
		height: 100%;
		background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/50598/concrete-wall-background.jpg) center center fixed;
		background-size: cover;
}







