@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
:root {
  --BWOrange: #cd561b;
  --BWWhite: whitesmoke;
}

.page-content {
  flex-grow: 1;
  margin-left: 50px;
  padding-left: 2%;
  transition: margin-left 0.2s ease-in-out;
}
.shifted-content {
  margin-left: 200px;
}

.center-text-column{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.orange_Button {
  justify-content: center;
  align-items: center;
  background-color: #cd561b;
  font-family: 'Permanent Marker';
  text-align: center;
  color: white;
  font-size: 28px;
  padding: 2px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.orange_Button_small {
  justify-content: center;
  align-items: center;
  background-color: #cd561b;
  font-family: 'Permanent Marker';
  text-align: center;
  color: white;
  font-size: 1.2rem;
  padding: 0px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.orange_Button:hover,
.orange_Button_small:hover {
    background-color: #e57036;
  }
  

.shade {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(150deg,
  rgb(0, 0, 0, 0.85) 0%,
  rgb(82, 34, 11, 0.6) 60%,
  rgb(164, 69, 22, 0.1) 100%);
}

.blackboard {
  width: 80%; /* Adjust the width as needed */
  padding: 10px;
  margin: 2% auto;
  border: tan solid 12px;
  border-top: #bda27e solid 12px;
  border-left: #b19876 solid 12px;
  border-bottom: #c9ad86 solid 12px;
  box-shadow: 0px 0px 6px 5px rgba(58, 18, 13, 0), 0px 0px 0px 2px #c2a782, 0px 0px 0px 4px #a58e6f, 3px 4px 8px 5px rgba(0, 0, 0, 0.5);
  background-image: radial-gradient(circle at left 30%, rgba(34, 34, 34, 0.3), rgba(34, 34, 34, 0.3) 80px, rgba(34, 34, 34, 0.5) 100px, rgba(51, 51, 51, 0.5) 160px, rgba(51, 51, 51, 0.5)), linear-gradient(215deg, transparent, transparent 100px, #222 260px, #222 320px, transparent), radial-gradient(circle at right, #111, rgba(51, 51, 51, 1));
  background-color: #333;
  overflow: auto;
}

.blackboard:before {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(175deg, transparent, transparent 40px, rgba(120, 120, 120, 0.1) 100px, rgba(120, 120, 120, 0.1) 110px, transparent 220px, transparent), linear-gradient(200deg, transparent 80%, rgba(50, 50, 50, 0.3)), radial-gradient(ellipse at right bottom, transparent, transparent 200px, rgba(80, 80, 80, 0.1) 260px, rgba(80, 80, 80, 0.1) 320px, transparent 400px, transparent);
  border: #2c2c2c solid 2px;
  font-size: 2.2em;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.form {
  padding: 0% 0% 3% 1%;
}

.chalktitle{
  font-family: 'Permanent Marker';
  font-size: 3rem;
  color: whitesmoke;
  text-align: center;
}

.chalk{
  font-family: 'Permanent Marker';
  font-size: var(--fsize);
  color: whitesmoke;
  text-align: var(--talign);
  display: block;
}
/* Refined Styles here */
.paragraph-chalk-white{
  font-family: 'Permanent Marker';
  color: var(--BWWhite);
  font-size: 1.5rem;
}

.paragraph-chalk-orange{
  font-family: 'Permanent Marker';
  color: var(--BWOrange);
}

.chalk-link,
.chalk-link:visited {
  font-family: 'Permanent Marker';
  color: var(--BWWhite);
  text-decoration: underline var(--BWOrange);
  font-size: 1.5rem;
}

.chalk-link:hover,
.chalk-link:visited:hover {
  color: var(--BWOrange);
}

.chalk-link:active,
.chalk-link:visited:active {
  color: #e57036;
}


.chalktitle-center{
  font-family: 'Permanent Marker';
  font-size: 40px;
  color: whitesmoke;
  text-align: center;
  display: block;
  margin: 0 auto; 
}

.Homepage-link,
.Homepage-link:visited {
  font-family: 'Permanent Marker';
  font-size: 32px;
  color: whitesmoke;
  text-decoration: underline #cd561b;
}

.Homepage-link:hover,
.Homepage-link:visited:hover {
  color: #cd561b;
}

.Homepage-link:active,
.Homepage-link:visited:active {
  color: #e57036;
}

.chalk-error{
  font-family: 'Permanent Marker';
  font-size: 14px;
  color: #ff0000;
  text-align: center;
  display: block;
  margin-bottom: 5%

}

.HomeLogo{
  max-width: 50%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

input[type=text],
input[type=password] {
  background-color: transparent;
  border: none;
  color: whitesmoke;
  border-bottom: 2px solid #cd561b;
  padding-left: 5px;
  font-size: 18px;
  max-width: 520px;
}

.sticky {
  -webkit-box-shadow: #DDD 0px 1px 2px;
  position: relative;
  background-color: #F4F39E;
  border-color: #DEE184;
  color: #47576B;
  text-align: center;
  margin: 0 0 2.5em; /* Removed top margin */
  padding: 1.5em 1em;
  -webkit-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  width: 250px;
}

.post-it {
  display: table;
  margin: 0.5em auto 0;
}

.taped {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.sticky.taped:after {
  display: block;
  content: "";
  position: absolute;
  width: 110px;
  height: 30px;
  top: -21px;
  left: 30%;
  border: 1px solid #fff;
  background: rgba(254, 254, 254, .6);
  -webkit-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  box-shadow: 0px 0 3px rgba(0,0,0,0.1);
}

.note {
  display: inline-block;
  -webkit-box-shadow: #DDD 0px 1px 2px;
  position: flex;
  background-color: #F4F39E;
  border-color: #DEE184;
  margin: 2.5em auto 1.5em; 
  padding: 0.75em 1em;
  text-align: center;
  -webkit-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(-2deg);
  width: 250px;
  font-size: 1em;
}

.note:after {
  display: block;
  content: "";
  position: absolute;
  width: 110px;
  height: 30px;
  top: -21px;
  left: 30%;
  border: 1px solid #fff;
  background: rgba(254, 254, 254, .6);
  -webkit-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0 3px rgba(0,0,0,0.1);
  box-shadow: 0px 0 3px rgba(0,0,0,0.1);
}

.profilepageportrait {
  position: relative;
  width: 225px;
  height: 225px;
  overflow: hidden;
  border-radius: 50%;
  border: 7px solid #cd561b;
  margin-top: 2.5em;
  margin-left: 2.5em;

}
.profilepageportrait img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.wageruserportrait {
  position: relative;
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 50%;
  border: 7px solid #cd561b;
}
.wageruserportrait img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

input[type="date"] {
  padding: 5px;
  border: 2px solid #cd561b;
  border-radius: 4px;
  background-color: transparent;
  color: whitesmoke;
  font-family: 'Permanent Marker';
  font-size: 16px;
}



.black-pen{
  font-family: 'Permanent Marker';
  font-size: var(--fsize);
  text-align: var(--talign);
  display: block;
}

.orange-chalk{
color: #cd561b;
font-family: 'Permanent Marker';
font-size: 1.5rem;
}

.small-chalk{
  color: whitesmoke;
  font-family: 'Permanent Marker';
  margin-bottom: 12px;
  }
  
.myGroupsInList,
.myGroupsInList:visited {
  font-family: 'Permanent Marker';
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
}

.myGroupsInList:hover {
  font-family: 'Permanent Marker';
  font-size: 1.2rem;
  color: #cd561b;
  border-bottom: 2px solid #cd561b;
}

.groupListLink {
  font-family: 'Permanent Marker';
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
}

.groupSearchResult{
  font-family: 'Permanent Marker';
  color: whitesmoke;
  text-decoration: none;
}

.groupSearchResult:hover{
  color: #cd561b;
}

.NewWager {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}


.NewWager form {
  width: 260px; 
  text-align: center;
}

.NewWager input {
  margin-bottom: 10px;
}

.HereFromPort{
    font-family: 'Permanent Marker';
    color: whitesmoke;
}
.HereFromPortOrange{
  font-family: 'Permanent Marker';
  color: var(--BWOrange);
  font-size: 1.5rem
}
.BlackOutBackground {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}
.ServerConnectPopUp {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  text-align: center;
  border-radius: 15px;
}

@keyframes fade {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.ServerBeerContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.ServerBeer {
  font-size: 3rem;
  color: var(--BWOrange);
  animation: spin 2s linear infinite, fade 2s infinite;
}

.ServerBeer:nth-child(1) {
  animation-delay: 0s;
}

.ServerBeer:nth-child(2) {
  animation-delay: .5s;
}

.ServerBeer:nth-child(3) {
  animation-delay: 1s;
}